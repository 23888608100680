// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiUrl: "http://172.16.16.191:3000",
  // apiUrl: 'https://int.fuzzycircuit.com',
  // apiUrl: "https://api.felicity.care",
  apiUrl : "https://api.fuzzycircuit.com",
  sessionUrl : "https://therapist.fuzzycircuit.com"
};
