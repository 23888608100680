import {Component, OnInit} from '@angular/core';
import { AdminAuthService } from '../../admin-auth.service';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit{
  public sidebarMinimized = false;
  public navItems = navItems;
  adminName = ''

  constructor(private authService: AdminAuthService){}

  ngOnInit(){
    this.adminName = localStorage.getItem('adminName');
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout(){
    this.authService.logout();
  }

}
