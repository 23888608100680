import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AdminAuthService } from '../../admin-auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  constructor(private authService: AdminAuthService){}
  ngOnInit(){}
  loginForm = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
    ]),
    password: new FormControl("", Validators.required),
  });

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.login(this.loginForm.value.email, this.loginForm.value.password);
  }
 }
