import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer",
  },
  {
    name: "User",
    icon: "cil-user",
    url: '/user',
    children: [
      {
        name: "Add user",
        url: "/base/user/add-user",
        icon: "cil-user-plus",
      },
      {
        name: "Add Plan",
        url: "/base/user/add-plan",
        icon: "icon-wallet",
      },
      {
        name: "List User",
        icon: "cil-spreadsheet",
        url: "/base/user/list-user",
      },
      {
        name: "List Clients",
        icon: "cil-spreadsheet",
        url: "/base/user/list-client",
      },
    ],
  },
  {
    name: "Admin",
    icon: "icon-lock",
    url: '/admin',
    children: [
      {
        name: "Add Admin",
        url: "/admin/add-admin",
        icon: "cil-user-plus",
      },
      {
        name: "List Admin",
        icon: "cil-spreadsheet",
        url: "/admin/list-admin",
      },
    ],
  },
  {
    name: "Offers",
    icon: "cil-diamond",
    url: '/offers',
    children: [
      {
        name: "Add Coupons",
        url: "/base/coupons/add-coupons",
        icon: "icon-plus",
      },
      {
        name: "List Coupons",
        icon: "cil-spreadsheet",
        url: "/base/coupons/list-coupons",
      },
    ],
  },
  {
    name: "Session",
    icon: "icon-camrecorder",
    url: '/session',
    children: [
      {
        name: "List Session",
        icon: "cil-spreadsheet",
        url: "/base/session/list-sessions",
      },
      {
        name: "Schedule Session",
        icon: "icon-plus",
        url: "/base/session/schedule-session",
      },
    ],
  },
  {
    name: 'Invoice',
    icon: 'cil-description',
    url: '/invoice',
    children: [
      {
        name: 'List Invoice',
        icon: 'cil-spreadsheet',
        url: '/base/invoice/list-invoice'
      },
      {
        name: 'Plan Transaction Logs',
        icon: 'cil-spreadsheet',
        url: '/base/invoice/plan-transaction-logs'
      }
    ]
  },
  {
    name: "Therapist",
    icon: "icon-star",
    url: '/therapist',
    children: [
      {
        name: "Add Therapist",
        icon: "icon-plus",
        url: "/base/therapist/add-therapist",
      },
      {
        name: "List Therapist",
        icon: "cil-spreadsheet",
        url: "/base/therapist/list-therapist",
      },
      {
        name: "Therapist Enquiries",
        icon: "cil-spreadsheet",
        url: "/base/therapist-enquiries/list",
      },
      {
        name : "Matching Tool",
        icon: "cil-spreadsheet",
        url : "/base/therapist/matching-tool"
      },
      {
        name : "Slot Report",
        icon : "cil-spreadsheet",
        url : "/base/therapist/slot-consumption-report"
      },
      {
        name : "Calendar Report",
        icon: "cil-spreadsheet",
        url: "/base/therapist/weekly-report"
      }
    ],
  },
  {
    name: "Free Report",
    icon: "icon-puzzle",
    url: '/free-report',
    children: [
      {
        name: "List Free Report",
        icon: "cil-spreadsheet",
        url: "/base/free-report/list-report",
      },
    ],
  },
  {
    name: "Category",
    icon: "icon-lock",
    url: '/category',
    children: [
      {
        name: "Add Category",
        icon: "icon-plus",
        url: "/base/category/add-category",
      },
      {
        name: "List Category",
        icon: "cil-spreadsheet",
        url: "/base/category/list-category",
      },
    ],
  },
  {
    name: "Reports",
    icon: "cil-bar-chart",
    url: '/reports',
    children: [
      {
        name: "Sales Report",
        icon: "icon-puzzle",
        url: "/base/reports/sales-report",
      },
      {
        name: "Plan Report",
        icon: "cil-spreadsheet",
        url: "/base/reports/plan-report",
      },
      {
        name: "Therapist Report",
        icon: "icon-star",
        url: "/base/reports/therapist-report",
      },
      {
        name: "User Report",
        icon: "cil-user",
        url: "/base/reports/user-report",
      },
    ],
  },
  {
    name: "Quote",
    icon: "cil-double-quote-sans-left",
    url: '/quote',
    children: [
      {
        name: "Update Quote",
        icon: "icon-puzzle",
        url: "/base/quote/update-quote",
      },
    ],
  },
  {
    name: "Help Desk",
    icon: "cil-headphones",
    url: '/help-desk',
    children: [
      {
        name: "User Helpdesk",
        icon: "cil-spreadsheet",
        url: "/base/userhelpdesk/list-userhelpdesk",
      },
      {
        name: "Therapist Helpdesk",
        icon: "cil-spreadsheet",
        url: "/base/therapisthelpdesk/list-therapisthelpdesk",
      },
    ],
  },
  {
    name: "Discussion Forum",
    icon: "cil-comment-bubble",
    url: "/base/discussion-forum/list-discussion",
    children: [
      {
        name: "List Discussion Forum",
        icon: "cil-spreadsheet",
        url: "/base/discussion-forum/list-discussion"
      }
    ]
  },
  {
    name: 'Therapist Requests',
    icon: 'cil-bank',
    url: '/therapist-requests',
    children: [
      {
        name: 'List Requests',
        icon: 'cil-spreadsheet',
        url: '/base/therapist-request/list-requests'
      }
    ]
  },
  {
    name: 'Enquiries',
    icon: 'cil-bank',
    url: '/enquiries',
    children: [
      {
        name: 'Contact Enquiries',
        icon: 'cil-spreadsheet',
        url: '/base/enquiries/contact'
      },
      {
        name: 'Corporate Enquiries',
        icon: 'cil-spreadsheet',
        url: '/base/enquiries/corporate'
      },
      {
        name: 'Callback Enquiries',
        icon: 'cil-spreadsheet',
        url: '/base/enquiries/callback'
      }
    ]
  },
  {
    name: 'Blogs',
    icon: 'cil-bank',
    url: '/blogs',
    children: [
      {
        name: 'Blogs List',
        icon: 'cil-spreadsheet',
        url: '/base/blog/blog-list'
      },
      {
        name: 'Add Blog',
        icon: 'icon-plus',
        url: '/base/blog/add-blog'
      }
    ]
  },
  {
    name: 'Therapist Reviews',
    icon: 'cil-bank',
    url: '/therapist-reviews',
    children: [
      {
        name: 'Therapist Reviews List',
        icon: 'cil-spreadsheet',
        url: '/base/therapist-review/review-list'
      },
      {
        name: 'Add Review',
        icon: 'cil-spreadsheet',
        url: '/base/therapist-review/add-review'
      }
    ]
  }
  // {
  //   title: true,
  //   name: 'Theme'
  // },
  // {
  //   name: 'Colors',
  //   url: '/theme/colors',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Typography',
  //   url: '/theme/typography',
  //   icon: 'icon-pencil'
  // },
  // {
  //   title: true,
  //   name: 'Components'
  // },
  // {
  //   name: 'Base',
  //   url: '/base',
  //   icon: 'icon-puzzle',
  //   children: [
  //     {
  //       name: 'Cards',
  //       url: '/base/cards',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Carousels',
  //       url: '/base/carousels',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Collapses',
  //       url: '/base/collapses',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Forms',
  //       url: '/base/forms',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Navbars',
  //       url: '/base/navbars',
  //       icon: 'icon-puzzle'

  //     },
  //     {
  //       name: 'Pagination',
  //       url: '/base/paginations',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Popovers',
  //       url: '/base/popovers',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Progress',
  //       url: '/base/progress',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Switches',
  //       url: '/base/switches',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tables',
  //       url: '/base/tables',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tabs',
  //       url: '/base/tabs',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tooltips',
  //       url: '/base/tooltips',
  //       icon: 'icon-puzzle'
  //     }
  //   ]
  // },
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Brand Buttons',
  //       url: '/buttons/brand-buttons',
  //       icon: 'icon-cursor'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Icons',
  //   url: '/icons',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'CoreUI Icons',
  //       url: '/icons/coreui-icons',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'success',
  //         text: 'NEW'
  //       }
  //     },
  //     {
  //       name: 'Flags',
  //       url: '/icons/flags',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Font Awesome',
  //       url: '/icons/font-awesome',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'secondary',
  //         text: '4.7'
  //       }
  //     },
  //     {
  //       name: 'Simple Line Icons',
  //       url: '/icons/simple-line-icons',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Notifications',
  //   url: '/notifications',
  //   icon: 'icon-bell',
  //   children: [
  //     {
  //       name: 'Alerts',
  //       url: '/notifications/alerts',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Badges',
  //       url: '/notifications/badges',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Modals',
  //       url: '/notifications/modals',
  //       icon: 'icon-bell'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   icon: 'icon-calculator',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   divider: true
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  // },
  // {
  //   name: 'Pages',
  //   url: '/pages',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Disabled',
  //   url: '/dashboard',
  //   icon: 'icon-ban',
  //   badge: {
  //     variant: 'secondary',
  //     text: 'NEW'
  //   },
  //   attributes: { disabled: true },
  // },
  // {
  //   name: 'Download CoreUI',
  //   url: 'http://coreui.io/angular/',
  //   icon: 'icon-cloud-download',
  //   class: 'mt-auto',
  //   variant: 'success',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // },
  // {
  //   name: 'Try CoreUI PRO',
  //   url: 'http://coreui.io/pro/angular/',
  //   icon: 'icon-layers',
  //   variant: 'danger',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // }
];
