import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";
import Swal from "sweetalert2";

const adminBackendUrl = environment.apiUrl + "/admin/";
@Injectable({
  providedIn: "root",
})
export class AdminAuthService {
  private isAuthenticated = false;
  private adminToken: string;
  private tokenTimer: NodeJS.Timer;
  private adminId: string;
  private authStatusListener = new Subject<boolean>();
  expiresIn: number = 86400000; //assuming token expires in 24hrs

  constructor(private http: HttpClient, private router: Router) {}

  getToken() {
    return this.adminToken;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.adminId;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  private saveAuthData(token: string, expirationDate: Date, userId: string, name: string) {
    localStorage.clear();
    localStorage.setItem("adminToken", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("adminId", userId);
    localStorage.setItem("adminName", name);
  }

  private getAuthData() {
    const token = localStorage.getItem("adminToken");
    const expirationDate = localStorage.getItem("expiration");
    const adminId = localStorage.getItem("adminId");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      adminId: adminId,
    };
  }

  private clearAuthData() {
    localStorage.clear();
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.adminToken = authInformation.token;
      this.isAuthenticated = true;
      this.adminId = authInformation.adminId;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }

  logout() {
    this.adminToken = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.adminId = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/login"]);
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  login(email: String, password: String) {
    const reqData = {
      email: email,
      password: password,
    };
    this.http.post<any>(adminBackendUrl + "login/login", reqData).subscribe(
      (response) => {
        const token = response.token;
        this.adminToken = token;
        if (token) {
          this.setAuthTimer(this.expiresIn);
          this.isAuthenticated = true;
          this.adminId = response.admin._id;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + this.expiresIn);
          this.router.navigate(["/dashboard"]);
          this.saveAuthData(token, expirationDate, this.adminId, response.admin.name);
        }
      },
      (error) => {
        if(error.error){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
          })
        }
        this.authStatusListener.next(false);
      }
    );
  }
}
